import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Notification, RadioButton } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/radio-button/customisation",
  "title": "RadioButton - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification type="alert" label="RadioButton customisation works differently!" className="siteNotification" mdxType="Notification">
  RadioButton is an older HDS component and therefore its customisation works slightly differently. Instead of the 
  <code>theme</code> property, you need to use the <code>style</code> property. This will be fixed in a future update.
    </Notification>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`className`}</inlineCode>{` properties to customise the component.`}</p>
    <p>{`See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the radio button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--icon-scale`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale of the radio button check icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Width of the radio button border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--outline-width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Width of the radio button focus border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the radio button border when selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color-selected-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the radio button border when selected and hovered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color-selected-focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the radio button border when selected and focused`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--icon-color-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the radio button selection circle`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--icon-color-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the radio button selection circle when hovered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--focus-outline-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the radio button focus border`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const [selectedItem, setSelectedItem] = React.useState('2');
    const onChange = (event) => {
      setSelectedItem(event.target.value);
    };
    const style = {
      '--size': '36px',
      '--icon-scale': 0.65,
      '--border-width': '3px',
      '--outline-width': '4px',
      '--border-color-selected': 'var(--color-tram)',
      '--border-color-selected-hover': 'var(--color-tram-dark)',
      '--border-color-selected-focus': 'var(--color-coat-of-arms)',
      '--icon-color-selected': 'var(--color-tram)',
      '--icon-color-hover': 'var(--color-tram-dark)',
      '--focus-outline-color': 'var(--color-coat-of-arms)',
    };
    return (
      <>
        <RadioButton
          id="radio1"
          name="example"
          label="Label"
          value="1"
          checked={selectedItem === '1'}
          style={style}
          onChange={onChange}
        />
        <RadioButton
          id="radio2"
          name="example"
          label="Label"
          value="2"
          checked={selectedItem === '2'}
          style={style}
          onChange={onChange}
        />
      </>
    );
  };
}
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      